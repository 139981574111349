#Navbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100vw;
    display: flex;
    text-align: center;
    z-index: 4;
    opacity: 1;
    border-bottom: .4em solid var(--main);
    background-color: var(--main);
    transition: all.2s;
}

.titles {
    display: flex;
    height: 100%;
    width: 100vw;
}

.titles a {
    text-decoration: none;
    color: var(--dark);
    font-weight: 400;
    width: 25%;
    max-width: 200px;
    border-top: .4em solid transparent;
    text-transform: uppercase;
    user-select: none;
}

.light {
    color: var(--light) !important;
}

#disconnect {
    float: right;
    background-color: var(--dark);
    border: none;
    margin: .4em;
    height: fit-content;
    border-radius: 10vh;
}

@media only screen and (max-width: 1035px) {

    #Navbar {
        display: flex;
        height: 10vh;
        border-bottom: .2em solid var(--dark);
    }

    .notel {
        display: none;
    }

    #hamburger,
    #cross {
        filter: sepia(5) hue-rotate(185deg);
        pointer-events: all !important;
        user-select: all !important;
        position: relative;
        float: left !important;
        margin: 1.5vh;
        height: 7vh !important;
        width: 7vh !important;
        background-color: transparent;
        border: none;
        background-position: center;
        background-repeat: no-repeat;
    }

    #hamburger {
        background-size: 100%;
        background-image: url("../../images/hamburger.png");
    }

    #cross {
        display: none;
        background-size: 50%;
        background-image: url("../../images/cross.png");
    }

    #hamburger:hover,
    #cross:hover {
        cursor: pointer;
    }

    #scrolled {
        display: none;
        position: absolute;
        top: 12vh;
        height: 45vh;
        width: 100%;
        text-align: center;
        background-color: transparent;
        align-items: center;
        vertical-align: middle;
    }

    #scrolled a {
        text-decoration: none;
        color: var(--dark);
        font-size: 1.4em;
        user-select: none;
    }

    .titles {
        width: 100vw;
        display: flex;
    }

    .titles a {
        font-size: 1.2em;
        width: 50%;
        line-height: 10vh;
        margin: 0 auto;
    }

    .titles #login {
        float: right;
        font-size: 1.2em;
        font-weight: bold;
        line-height: 5vh;
        display: block;
        border: .1em solid var(--dark);
        margin: 1em;
        margin-top: .1em;
        min-width: 150px;
        border-radius: 10vh;
    }

    #disconnect {
        padding: 1em;
        width: fit-content;
        margin: 0 auto;
    }

}

@media only screen and (min-width: 1035px) {

    #Navbar {
        display: inline-flex;
        height: 6vh;
    }

    #logo {
        margin-left: 25px;
    }

    #hamburger,
    #cross,
    #scrolled {
        display: none;
    }

    .titles a {
        float: left;
        font-size: 1.2em;
        line-height: 6vh;
        margin: 0 44px;
    }

    .titles a:hover {
        cursor: pointer;
        border-top: .4em solid var(--dark);
    }

    .co {
        width: fit-content;
        margin-left: auto !important;
        order: 2;
    }

    #login,
    #disconnect {
        width: 200px;
        margin-left: 22px;
        margin-right: 22px;
    }

    #login {
        margin: .3em;
        padding: 0;
        height: fit-content;
        border: .1em solid var(--dark);
        border-radius: 10vh;
    }

    #login:hover {
        background-color: rgb(29, 38, 70, .17);
    }

    #disconnect:hover {
        filter: contrast(1.4);
    }

}