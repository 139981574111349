.card {
    position: relative;
    background-color: rgb(251, 252, 246, .1);
    height: fit-content;
    max-width: 750px;
    border-radius: 9px 9px 0 0;
    margin: 0 auto;
}

.big {
    margin: 0 auto;
    z-index: 2;
    margin-bottom: 25vh;
    height: auto;
    overflow-y: visible;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    border-radius: 0 0 9px 9px;
    gap: 17px;
    backdrop-filter: blur(2em);
}

.btn,
.sync {
    margin-left: auto;
    margin-right: auto;
    margin: 0 auto;
    border-radius: 9px;
    padding: 9px;
}

.btn {
    left: 0;
    width: 200px;
    background-color: var(--dark);
    color: var(--light);
    font-size: 1.7em;
    border: none;
    margin: 9px auto !important;
}

.red {
    filter: brightness(2) sepia(20) hue-rotate(-50deg) saturate(7);
}

.special {
    color: var(--main-plus);
    width: 400px !important;
    max-width: 95vw !important;
    font-size: 1.4em;
    background-color: transparent;
    border: .1em solid var(--main-plus);
    margin: 0 auto !important;
    transition: all.2s;
}

.special:hover {
    filter: brightness(.81) !important;
}

.special:target,
.special:active,
.special:focus {
    animation: pressed .4s;
}

@keyframes pressed {

    0% {
        transform: scale(1);
    }

    30% {
        transform: scale(.9);
        background-color: var(--main-plus);
        color: var(--main);
    }

    75% {
        transform: scale(1.05);
        background-color: var(--main-plus);
        color: var(--main);
    }

    100% {
        transform: scale(.99);
    }

}

#input {
    width: calc(90vw + 32px);
    max-width: 782px !important;
    color: var(--light);
    font-size: 2em;
}

.btn:hover,
.sync:hover {
    cursor: pointer;
}

.btn:hover {
    filter: contrast(1.4);
}

.sync:hover {
    background-color: rgb(207, 181, 61, .17);
}

.card p,
.card input {
    padding: 9px;
    border: .1em solid var(--dark);
    height: fit-content;
    color: var(--dark);
    font-size: 1.2em;
}

.card input {
    border-radius: 9px;
    padding-top: 1em;
    padding-bottom: 1em;
}

#new_element {
    float: left;
    text-transform: capitalize;
}

#quantite {
    float: right;
}

.card p {
    border-radius: 9px;
    position: relative;
    width: 140px;
    height: 50px;
    line-height: 50px;
    padding-right: 3.5em;
    margin: 0 auto;
}

.card p span {
    position: absolute;
    top: 0;
    right: -.1em;
    width: 2em;
    height: calc(50px - .1em);
    background-color: var(--dark);
    border: .1em solid var(--dark);
    color: var(--light);
    border-radius: 0 9px 9px 0;
    padding: 9px;
}

.card p:hover {
    cursor: pointer;
    animation: tremble .07s infinite;
    animation-timing-function: linear;
    filter: brightness(2) sepia(20) hue-rotate(-50deg) saturate(7);
}

@keyframes tremble {
    0% {
        transform: rotateZ(0deg);
    }

    44% {
        transform: rotateZ(1deg) translateX(2px);
    }

    81% {
        transform: rotateZ(-1deg) translateX(-2px);
    }

    100% {
        transform: rotateZ(0deg);
    }
}

.separator {
    width: 90%;
    height: .2em;
    margin: 25px auto;
    margin-bottom: 10px;
    background-color: var(--main-plus);
    opacity: .33;
    border-radius: .2em;
}

.card input {
    width: calc(50% - 1.5em);
    margin: 0 auto;
}

input {
    opacity: 1 !important;
}

@media only screen and (min-width: 1300px) {

    .card {
        padding: 1em;
        width: 90vw;
    }

    .minion {
        position: fixed;
        bottom: 0;
        right: 0;
        height: 30vh;
        width: 44vh;
        background-image: url(../../images/minions.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .red {
        transform: translateX(10px);
    }

    .red:hover {
        filter: brightness(.9);
        background-color: red !important;
    }

    .blue {
        transform: translateX(-10px);
    }

}

@media only screen and (max-width: 1300px) {

    .card {
        padding: .5em;
        width: 95vw;
    }

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    appearance: textfield;
}

#alertmessage {
    display: none;
    z-index: 999;
    position: fixed;
    top: 100px;
    right: 25px;
    width: 250px;
    border-radius: 17px 0 17px 17px;
    padding: 40px 0px;
    font-size: 1.4em;
    font-weight: bold;
    text-transform: uppercase;
    height: fit-content;
    background-color: var(--dark);
    color: var(--light);
}

.smalls {
    transform: translateY(-28px) !important;
    display: flex !important;
    flex-wrap: wrap !important;
    width: 100% !important;
    max-width: 580px !important;
    margin: 0 auto !important;
}

.small {
    margin: 0 auto !important;
    padding: 0 !important;
    width: 125px !important;
    height: 125px !important;
    line-height: 125px !important;
    border-radius: 125px !important;
}

#details {
    width: 100%;
    max-width: 786px !important;
    padding: 25px 0;
    border-radius: 9px !important;
    background-color: rgb(251, 252, 246, .1);
    display: none;
    opacity: 0;
}

#showparam {
    margin-bottom: 50px !important;
}