.App {
  text-align: center;
  padding-top: 12vh;
}

h1 {
  color: var(--dark);
  font-weight: 500;
  margin-bottom: 5vw;
}

a,
p,
span,
input,
div {
  color: var(--dark);
}

.App p {
  display: grid;
  width: 100%;
  gap: 25px;
  margin: 0 auto;
  margin-bottom: 50px;
}

.App p .info {
  color: var(--main-plus);
  font-size: 1.4em;
  margin: 0;
  padding: 0;
}

.App p input {
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 25px;
  border-radius: 9px;
  color: var(--main-plus);
  border: 2px solid var(--main-plus);
}

.App p input:active,
.App p input:target,
.App p input:focus {
  color: var(--dark);
  border: 2px solid var(--dark);
}